<template>
   <!-- ::::::  Start Hero Section  ::::::  -->
    <div class="hero slider-dot-fix slider-dot slider-dot-fix slider-dot--center slider-dot-size--medium slider-dot-circle  slider-dot-style--fill slider-dot-style--fill-white-active-green dot-gap__X--10">
        <carousel3 :autoplay="true" v-if="showData" :nav="false" :items="1" :responsive="{ 0: { items: 1 }, 576: { items: 1 }, 600: { items: 1 }, 768: { items: 1 }, 1200: { items: 1 }, 1500: { items: 1 },}">
            <!-- Start Single Hero Slide -->
            <div class="hero-slider" v-for="slider in allSlider" :key="slider.id">
               <div class="slider-overflow"></div>
                <img :src="$root.URL_ROOT+'uploads/Slider/'+slider.image" alt="">
                <div class="hero__content">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-8">
                                <div class="hero__content--inner">
                                    <h6 class="title__hero title__hero--tiny text-uppercase white-text"> {{slider.heading}} </h6>
                                    <h1 class="title__hero title__hero--xlarge font--regular text-uppercase white-text"> {{slider.heading2}} </h1>
                                    <a :href="slider.link" class="btn btn--large btn--radius btn--black btn--black-hover-green font--bold text-uppercase">Shop now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <!-- End Single Hero Slide -->
        </carousel3>
    </div> <!-- ::::::  End Hero Section  ::::::  -->
</template>
<script>
import carousel3 from 'vue-owl-carousel2'
export default {
    components: {
        carousel3
    },
    data() {
        return {
            allSlider: [],
            showData: false
        };
    },
    mounted() {
        this.SliderData();

    },
  
    methods: {
      SliderData(){
            const axios = require('axios')
            var self = this
            axios.post(this.$root.URL_ROOT + 'api.php', {
                type:'home_silder'
            }).then(function (response) {
                var data = response.data
                self.allSlider = data.data
                self.showData = true
            })
      }

    },
};
</script>
<style scoped>
.owl-carousel .owl-item img{
    max-height: 500px;
}
</style>