import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login'
import Myaccount from '../views/myaccount'
import Home from '../views/Home.vue'
var user = localStorage.getItem('user')
Vue.use(VueRouter)
const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
  path: '/product/:name?/:id?/:key?',
    name: 'Product',
    component: () =>
    import('../views/product.vue')
},
{
    path: '/productDetail/:id',
    name: 'productDetail',

    component: () =>
        import('../views/Detail.vue')
},
{
    path: '/login/',
    name: 'login',
    component: () =>
        import('../views/login.vue')
},

{
  path: '/CorsCheck/',
  name: 'CorsCheck',
  component: () =>
      import('../views/CorsCheck.vue')
},

// {
//   path: '/textInvoice/',
//   name: 'textInvoice',
//   component: () =>
//       import('../views/textInvoice.vue')
// },
// {
//   path: '/gallery/',
//   name: 'gallery',
//   component: () =>
//       import('../views/Gallery.vue')
// },
{
    path: '/registration/',
    name: 'registration',
    component: () =>
        import('../views/registration.vue')
},
{
    path: '/myaccount/',
    name: 'myaccount',
    component:  Myaccount,
},
{
    path: '/aboutus/',
    name: 'aboutus',
    component: () =>
        import('../views/aboutus.vue')
},
{
  path: '/invoice/:id',
  name: 'invoice',
  component: () =>
      import('../views/Invoice.vue')
},

{
    path: '/wishlist/',
    name: 'wishlist',
    component: () =>
        import('../views/wishlist.vue')
},
{
    path: '/contactus',
    name: 'contactus',
    component: () =>
        import('../views/contactus.vue')
},
// {
//     path: '/privecypolicy',
//     name: 'privecypolicy',
//     component: () =>
//         import('../views/privecypolicy.vue')
// },
// {
//     path: '/TermsConditions',
//     name: 'TermsConditions',
//     component: () =>
//         import('../views/TermsConditions.vue')
// },
{
    path: '/cart',
    name: 'cart',
    component: () =>
        import('../views/cart.vue')
},
{
    path: '/checkout',
    name: 'checkout',
    component: () =>
        import('../views/checkout.vue')
},
{
  path: '/guestcheckout',
  name: 'guestcheckout',
  component: () =>
      import('../views/guestcheckout.vue')
},
{
    path: '/success',
    name: 'success',
    component: () =>
        import('../views/success.vue')
},
{
  path: '/fail',
  name: 'fail',
  component: () =>
      import('../views/fail.vue')
},
{
  path: '/success_cod',
  name: 'success_cod',
  component: () =>
      import('../views/success_cod.vue')
},
// {
//     path: '/orderDetail',
//     name: 'orderDetail',
//     component: () =>
//         import('../views/orderDetail.vue')
// },
{
    path: '/forget',
    name: 'forget',
    component: () =>
        import('../views/forget.vue')
},
{
    path: '/resetpassword',
    name: 'resetpassword',
    component: () =>
        import('../views/resetpassword.vue')
},
{
  path: '/faq',
  name: 'faq',
  component: () =>
      import('../views/Faq.vue')
},
// {
//   path: '/shippingtest',
//   name: 'shippingtest',
//   component: () =>
//       import('../views/ShippingTest.vue')
// },
{
  path: '/TrackOrder/:id',
  name: 'TrackOrder',
  component: () =>
      import('../views/TrackOrder.vue')
}

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var user = localStorage.getItem('user')
  var check_2 = localStorage.getItem('local_mobile');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      if(check_2!=null){
        var paths='/Signup';
      }else{
       var paths='/Step1';
      }
      next({
        path: paths,
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.requiresNoAuth)) {
    if (user) {
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
