<template>
 <!-- start page-wrapper -->
<body>
<!-- ::::::  Start Header Section  ::::::  -->
<Header/>
<!-- :::::: End Header Section ::::::  -->  
<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"> <router-link to="/">Home</router-link></li>
                    <li class="page-breadcrumb__nav active">Login</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
<!-- ::::::  Start  Main Container Section  ::::::  -->
<main id="main-container" class="main-container inner-page-container">
    <div class="container">
        <div class="row">
            <div class="col-12 offset-md-3">
                <div class="section-content m-b-40">
                    <h5 class="section-content__title text-center">Login</h5>
                </div>
            </div>
            <!-- Start Login Area -->
            <div class="col-lg-6 col-12  offset-md-3">
                <div class="login-form-container card">
                    <div class="login-register-form">
                        <form action="#" method="post">
                            <div class="form-box__single-group">
                                <label for="form-username">Username or email address *</label>
                                <input type="text" v-model="username" id="form-username" placeholder="Username" required>
                            </div>
                            <div class="form-box__single-group">
                                <label for="form-username-password">Password *</label>
                                <div class="password__toggle">
                                    <input type="password"  v-model="password"  id="form-username-password" placeholder="Enter password" required>
                                    <span data-bs-toggle="#form-username-password" class="password__toggle--btn fa fa-fw fa-eye"></span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between flex-wrap m-tb-20">
                                <label for="account-remember">
                                    
                                </label>
                                    <router-link class="link--gray" :to="{name:'forget'}">Forgot Password?</router-link>
                            </div>
                             <div class="text-center">
                            <button class="my-3 w-100 btn btn--box btn--medium btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold" type="button"  @click="login" >LOGIN</button>
                    </div>
                       
                        </form>
                        <div class="text-center">

                            <h4 class="or"><span>OR</span></h4>
                            <p class="subText">Don't have an account? <router-link :to="{name:'registration'}">Create free
                                    account</router-link></p>
                    </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</main> <!-- ::::::  End  Main Container Section  ::::::  -->

<!-- ::::::  Start  Footer ::::::  -->
<Footer/>
<!-- ::::::  End  Footer ::::::  -->

<!-- material-scrolltop button -->
</body>
    <!-- end of page-wrapper -->
</template>

<script>
import Header from '@/components/navbar'
import Footer from '@/components/footer'
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            isHas: false,
            passshow: 'password',
            username: '',
            password: '',
            role: '',
            token: localStorage.getItem('token'),
            msg: []
        }
    },
    mounted() {
        // this.login()
        this.uid = localStorage.getItem('user')
        this.check()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        check() {
            if (this.uid != null) {
                window.location.href = 'myaccount';
            }
        },
        login() {
            var vi = this
            const axios = require('axios')
            var ip_address = JSON.parse(localStorage.getItem('ip_address'))
            if (vi.username === '') {
                vi.$toasted.global.error({
                    message: 'Please enter username'
                })
            } else if (vi.password === '') {
                vi.$toasted.global.error({
                    message: 'Please enter password'
                })
            } else {
                axios({
                    method: 'POST',
                    url: vi.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'userlogin',
                        username: vi.username,
                        password: vi.password,
                        ip_address: ip_address
                    }
                }).then(function (response) {
                    var data = response.data
                   if (data.status === 'success') {
                       localStorage.setItem('user', data.session.uid)
                        localStorage.setItem('token', data.session.uid)
                        vi.$toasted.global.success({
                            message: 'Login Success Please wait...'
                        })
                        // setTimeout(() => {
                        vi.$router.push({ name: 'myaccount' });
                        vi.$router.push('/myaccount');
                        // }, 2000);
                      
                    } else {
                        vi.$toasted.global.error({
                            message: 'Invalid id or password'
                        })
                    }
                })
            }
            return false
        }
    }
}
</script>
