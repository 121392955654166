<template>
<div class="company-logo m-t-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="company-logo__area default-slider">
                    <!-- Start Single Company Logo Item -->
                    <carousel2 :autoplay="false" v-if="showData" :nav="false" :items="6" :responsive="{ 0: { items: 6 }, 576: { items: 1 }, 600: { items: 1 }, 768: { items: 1 }, 1200: { items: 6 }, 1500: { items: 6 },}">
                        <div class="company-logo__item" v-for="page in pageData" :key="page.id">
                            <a href="#" class="company-logo__link">
                                <img :src="$root.URL_ROOT+'uploads/Content/'+page.image" alt="" class="company-logo__img">
                            </a>
                        </div>
                    </carousel2>
                    <!-- End Single Company Logo Item -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import carousel2 from 'vue-owl-carousel2'
import axios from "axios";

export default {
    components: {
        carousel2,
    },
    data() {
        return {
            pageData: [],
            showData: false
        }
    },
    mounted() {
        this.uid = localStorage.getItem('user')
        this.pData()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        pData() {
            const axios = require('axios')
            var self = this
            axios.post(this.$root.URL_ROOT + 'api.php', {
                type: 'partners'
            }).then(function (response) {
                var data = response.data
                self.pageData = data.data
                self.showData = true
            })
        }
    }
}
</script>
