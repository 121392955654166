<template>
       <div class="col-md-6 col-12">
                        <div class="banner__box">
                            <!-- Start Single Banner Item -->
                            <div class="banner__box--single banner__box--single-text-style-1 pos-relative">
                                <a :href="pageData.link" class="banner__link">
                                    <img :src="$root.URL_ROOT+'uploads/Content/'+pageData.image" alt="" class="banner__img">
                                </a>
                                <div class="banner__content banner__content--center pos-absolute">
                                    <!-- <h6 class="banner__title letter-spacing--4 font--regular text-center m-b-10">Fresh herbs</h6> -->
                                    <h2 class="banner__title banner__title--large letter-spacing--4 font--medium text-uppercase" style="color:#9b272c">{{pageData.heading}}</h2>
                                    <h6 class="banner__title letter-spacing--4 font--regular text-center m-b-20" v-html="pageData.description"></h6>
                                    <div class="text-center">
                                        <a :href="pageData.link" class="btn btn--medium btn--radius btn--transparent btn--black btn--border-black-hover-green font--light text-uppercase btn--black-hover-green">Buy Now</a>
                                    </div>
                                </div>
                            </div> <!-- End Single Banner Item -->
                        </div>
                    </div>
</template>
<script>
import carousel from 'vue-owl-carousel2'
export default {
    components: {
        carousel,
    },
    data() {
        return {
            pageData: [],
            showData: false
        }
    },
    mounted() {
        this.pData()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        pData() {
            const axios = require('axios')
            var self = this
            axios.post(this.$root.URL_ROOT + 'api.php', {
                type:'home_content-1'
            }).then(function (response) {
                var data = response.data
                self.pageData = data[1]
                self.showData = true

            })
        }
    }
}
</script>