<template>
<div class="product-default-slider-4grid-2rows gap__col--30 gap__row--40">
<carousel :autoplay="false" :nav="false" :items="4" :responsive="{ 0: { items: 2 }, 576: { items: 2 }, 600: { items: 2 }, 768: { items: 2 }, 1200: { items: 4 }, 1500: { items: 4 },}">
<div class="product__box product__default--single text-center" v-for="(product,rd) in productData" :key="rd">
    <!-- Start Product Image -->
    <div class="product__img-box  pos-relative">
        <router-link :to="{ name: 'productDetail', params: { id: product.id } }" class="product__img--link">
            <img class="product__img img-fluid p-img-css" :src="$root.URL_ROOT+'uploads/Product/'+product.front_image" alt="">
        </router-link>
        <!-- Start Product Action Link-->
        <ul class="product__action--link pos-absolute">
            <li><a  @click="add_to_cart(product.id,product.name,'1',product.selling_cost)" class="pointer" ><i class="icon-shopping-cart"></i></a></li>
            <!-- <li><a href="compare.html"><i class="icon-sliders"></i></a></li> -->
            <li><a @click="whishList(product.id)" class="pointer"><i class="icon-heart"></i></a></li>
            <!-- <li><a href="#modalQuickView" data-bs-toggle="modal"><i class="icon-eye"></i></a></li> -->
        </ul> <!-- End Product Action Link -->
    </div> <!-- End Product Image -->
    <!-- Start Product Content -->
    <div class="product__content m-t-20">
        <ul class="product__review">
            <li class="product__review--fill"><i class="icon-star"></i></li>
            <li class="product__review--fill"><i class="icon-star"></i></li>
            <li class="product__review--fill"><i class="icon-star"></i></li>
            <li class="product__review--fill"><i class="icon-star"></i></li>
            <li class="product__review--blank"><i class="icon-star"></i></li>
        </ul>
        <router-link :to="{ name: 'productDetail', params: { id: product.id } }" class="product__link"> {{product.name}} </router-link>
        <div class="product__price m-t-5">
            <span class="product__price">INR {{product.selling_cost}}  <del><template v-if="product.purchese_cost != 0">INR {{product.purchese_cost}}</template></del></span>
        </div>
    </div> <!-- End Product Content -->
</div>
</carousel>
</div>
</template>

<script>
// import starRating from "@/components/starRating";
import carousel from "vue-owl-carousel";
import axios from "axios";

export default {
    props: ["productData", "keyVal"],
    components: {
        carousel,
        // starRating,
    },
    data() {
        return {
            whishlist: 0,
        };
    },
    mounted() {
        this.orderDetail()
    },
    methods: {
        orderDetail() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'myorder',
                    uid: ev.uid,

                }
            }).then(function (response) {
                var data = response.data.data
                ev.orderDetailData = data
            })
        },
    },
};
</script>

<style scoped>
.owl-carousel {
    display: block;
}

.product-carousel {
    display: block !important;
}

.product-thumb .button-group a {
    width: 40px;
    height: 40px;
    display: unset;
    top: 8px;
    border: none;
    background-color: #f2f2f2;
    color: #333;
    padding: 12px 12px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    transition: none;
    vertical-align: top;
    margin-right: 0px;
}
</style>
