<template>
  <div id="app">
  <router-view/>
  </div>
</template>
<script>
 import axios from 'axios'
  //  Vendor CSS Files 
    require('./assets/css/vendor/jquery-ui.min.css')
    require('./assets/css/vendor/fontawesome.css')
    require('./assets/css/vendor/plaza-icon.css')
    require('./assets/css/vendor/bootstrap.min.css')
    //  Plugin CSS Files
    require('./assets/css/plugin/slick.css')
    require('./assets/css/plugin/material-scrolltop.css')
    require('./assets/css/plugin/price_range_style.css')
    require('./assets/css/plugin/in-number.css')
    require('./assets/css/plugin/venobox.min.css')
    require('./assets/css/plugin/jquery.lineProgressbar.css')
    //  Main Style CSS File 
    require('./assets/css/main.css')
export default {
data () {
  return {
    ip_address: JSON.parse(localStorage.getItem('ip_address')),
    ratings: 0
  }
 },
 mounted () {
   this.goToTop ()
  //  this.getConfig()
   this.myIpData()
   this.ip_address =JSON.parse(localStorage.getItem('ip_address'))
   this.uid = localStorage.getItem('user')
   this.setRatings();
 },
 methods: {
   setRatings(){
      var ev = this;
            axios
                .post(this.$root.URL_ROOT + "api.php?type=setRating")
                .then(function (response) {
                    var data = response.data
                    // console.log(response)
                    if (data.status == 'success') {
                        ev.ratings = data.data;
                    }
                });
   },
    // async getConfig () {
    //   const response = await axios.get(this.$root.URL_ROOT + 'api.php?type=linksdetails')
    //   this.$store.commit('getConfig', response.data.data)
    // },
    goToTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    myIpData () {
      var ip_address = JSON.parse(localStorage.getItem('ip_address'))

      if (ip_address == null) {
        var min = 100000
        var max = 999999
        var num = Math.floor(Math.random() * (max - min + 1)) + min
        localStorage.setItem('ip_address', JSON.stringify(num))
      }
    },
  }
 }
</script>
<style>
/* @import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css); */

.product-wrap .product-item .product-related-content {
  padding: 20px;
  background: #fff;
  text-align:center;
}

.product-wrap .product-item .product-related-content h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .product-wrap .product-item .product-related-content h3 {
    font-size: 18px;
  }
}

.product-wrap .product-item .product-related-content h3 a {
  color: #30180d;
}

.product-wrap .product-item .product-related-content h3 a:hover {
  color: #ffb015;
}

.product-wrap .product-item .product-related-content .product-btm {
  display: block;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #f00;
    font-weight: bold;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product-wrap .product-item .product-related-content .product-btm .product-price ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-wrap .product-item .product-related-content .product-btm .product-price ul li {
  color: #a18d84;
  font-weight: 500;
}

.product-wrap .product-item .product-related-content .product-btm .product-price ul li:first-child {
  color: #ffb015;
  margin-right: 10px;
}

.product-wrap .product-item .product-related-content .product-btm .product-price ul li:last-child {
  text-decoration: line-through;
}

.product-wrap .product-item .product-related-content .product-btm .product-ratting ul {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
}

.product-wrap .product-item .product-related-content .product-btm .product-ratting ul li {
  padding-right: 3px;
  color: #ff9600;
}

.product-wrap .product-item .product-related-content .product-btm .product-ratting ul li span {
  color: #c2beb3;
}
.related-product-margin{
  margin: 0px 0 8px 90px;
}
.product-main-image{
  width:100%;
  background: #f1f1f1;
  padding:5px;
  margin: 0 20px;
}
.thumb-img{
      max-width: 100px;
    background: #f1f1f1;
    padding: 5px;
}
.line-height-8{
      line-height: 8;
}
.qtybutton i {
    font-size: 16px;
    font-weight: 100;
}
.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}
.note{
    font-size: 16px;
    color: #ff0011;
    font-weight: bold;
}
.show-me{
  opacity: 1 !important;
  visibility: visible !important;
}
</style>
