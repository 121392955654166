<template>
 <!-- ::::::  Start  Footer ::::::  -->
    <footer class="footer m-t-100">
        <div class="container">
            <!-- Start Footer Top Section --> 
            <div class="footer__top">
                <div class="row">
                    <div class="col-lg-4 col-md-5">
                        <div class="footer__about">
                            <!-- <div class="footer__logo">
                                <router-link to="/" class="footer__logo-link">
                                    <img  :src="$root.URL_ROOT+'uploads/Content/'+site_logo.image"  alt="" class="footer__logo-img">
                                </router-link>
                            </div> -->
                            <ul class="footer__address">
                                <li class="footer__address-item" v-if="LinksDetails.address"><i class="fa fa-home"></i>
                                <span v-html="LinksDetails.address"></span>
                                </li>
                                <li class="footer__address-item" v-if="LinksDetails.mobile"><i class="fa fa-phone-alt"></i><a class="theme-color" :href="'tel:'+LinksDetails.mobile"> {{LinksDetails.mobile}} </a></li>
                                <li class="footer__address-item" v-if="LinksDetails.email"><i class="fa fa-envelope"></i><a :href="'mailto:'+LinksDetails.email">{{LinksDetails.email}}</a></li>
                            </ul>
                            <ul class="footer__social-nav">
                                <li class="footer__social-list"  v-if="LinksDetails.facebook_link"><a :href="LinksDetails.facebook_link" class="footer__social-link"><i class="fab fa-facebook-f"></i></a></li>
                                <li class="footer__social-list"  v-if="LinksDetails.twitter_link"><a :href="LinksDetails.twitter_link" class="footer__social-link"><i class="fab fa-twitter"></i></a></li>
                                <li class="footer__social-list" v-if="LinksDetails.instagram_link"><a :href="LinksDetails.instagram_link" class="footer__social-link"><i class="fab fa-instagram"></i></a></li>
                                <li class="footer__social-list" v-if="LinksDetails.google_link"><a :href="LinksDetails.google_link" class="footer__social-link"><i class="fab fa-google-plus-g"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <!-- Start Footer Nav -->  
                        <div class="footer__menu">
                            <h4 class="footer__nav-title footer__nav-title--dash footer__nav-title--dash-red">INFORMATION</h4>
                            <ul class="footer__nav">
                                <li class="footer__list">
                                        <router-link :to="{ name: 'aboutus' }" class="footer__link">About Us</router-link>
                                    </li><!-- End Single Nav link-->
                                    <!--Start Single Nav link-->
                                    <li class="footer__list">
                                        <router-link :to="{name:'faq'}" class="footer__link">Frequently Questions</router-link>
                                    </li> <!-- End Single Nav link-->
                                    <li class="footer__list">
                                        <router-link :to="{ name: 'contactus' }" class="footer__link">Contact Us</router-link>
                                    </li> <!-- End Single Nav link-->
                            </ul>
                        </div> <!-- End Footer Nav --> 
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 col-12">
                        <div class="footer__menu">
                            <h4 class="footer__nav-title footer__nav-title--dash footer__nav-title--dash-red">MY ACCOUNT</h4>
                            <ul class="footer__nav">
                                  <li class="footer__list" v-if="uid">
                                        <router-link class="footer__link"  :to="{name: 'myaccount'}">My Account</router-link>
                                    </li>
                                    <li class="footer__list" v-if="!uid">
                                        <router-link class="footer__link" :to="{name: 'login'}">Login</router-link>
                                    </li>
                                    <li class="footer__list" v-if="!uid">
                                        <router-link class="footer__link"  :to="{name: 'registration'}">Register</router-link>
                                    </li>
                                    <li class="footer__list">
                                        <router-link class="footer__link"  :to="{name: 'cart'}">My Cart</router-link>
                                    </li>
                                <li class="footer__list">
                                        <router-link class="footer__link"  :to="{name: 'wishlist'}">Wishlist</router-link>
                                    </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-12">
                        <div class="footer__menu">
                            <h4 class="footer__nav-title footer__nav-title--dash footer__nav-title--dash-red">About us</h4>
                             <p  v-if="LinksDetails.footer_content" > {{LinksDetails.footer_content}} </p>
                        </div>
                    </div>
                </div>
            </div> <!-- End Footer Top Section -->
            <!-- Start Footer Bottom Section --> 
            <div class="footer__bottom">
                <div class="row">
                    <div class="col-lg-8 col-md-6 col-12">
                        <!-- Start Footer Copyright Text -->
                        <div class="footer__copyright-text">
                            <p>Copyright &copy; <a href="https://www.5ines.com/" target="_blank">5INE WEB SOLUTIONS PVT.LTD</a>. All Rights Reserved</p>
                        </div> <!-- End Footer Copyright Text -->
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                         <!-- Start Footer Payment Logo -->
                        <div class="footer__payment">
                            <a href="#" class="footer__payment-link">
                                <img src="assets/img/company-logo/payment.png" alt="" class="footer__payment-img">
                            </a>
                        </div>  <!-- End Footer Payment Logo -->
                    </div>
                </div>
            </div> <!-- End Footer Bottom Section --> 
        </div>
    </footer> <!-- ::::::  End  Footer ::::::  -->

</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      catedatas: [],
      footData: [],
      email: "",
      LinksDetails: [],
      site_logo: [],
      uid: localStorage.getItem("user"),
    };
  },
  mounted() {
    this.footerData();
    this.site_setting()
    this.site_logos();
    // this.sendEmail()
    // console.log(this.cdata)
  },
  methods: {
    footerData() {
      const axios = require("axios");
      var self = this;
      axios
        .get(this.$root.URL_ROOT + "api.php?type=footer_menu")
        .then(function (response) {
          self.footData = response.data.data;
          // console.log(response.data.data)
        });
    },
    sendEmail() {
      var ev = this;
      axios({
        method: "POST",
        url: this.$root.URL_ROOT + "api.php",
        data: { type: "subscribe", email: this.email },
      }).then(function (response) {
        console.log(response);
        if (response.data.status == "success") {
          ev.email = "";
          alert(response.data.msg);
        } else {
          alert(response.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
</style>