<template>
<!-- start page-wrapper -->

<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header />
    <!-- :::::: End Header Section ::::::  -->

    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="page-breadcrumb__nav active">My Account</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- :::::::::: Start My Account Section :::::::::: -->
                    <div class="my-account-area">
                        <div class="row">
                            <div class="col-xl-3 col-md-4">
                                <div class="my-account-menu">
                                    <ul class="nav account-menu-list flex-column nav-pills" id="pills-tab" role="tablist">
                                        <li>
                                            <a @click="open_dashboard('step-1')" :class="dashboard_box == 'step-1' ? 'active' : ''" class="link--icon-left" id="pills-dashboard-tab" data-bs-toggle="pill" role="tab" aria-controls="pills-dashboard" aria-selected="true"><i class="fas fa-tachometer-alt"></i> Dashboard</a>
                                        </li>
                                        <li>
                                            <a @click="open_dashboard('step-2')" :class="dashboard_box == 'step-2' ? 'active' : ''" id="pills-order-tab" class="link--icon-left" data-bs-toggle="pill" role="tab" aria-controls="pills-order" aria-selected="false"><i class="fas fa-shopping-cart"></i> Order</a>
                                        </li>
                                        <li>
                                            <a @click="open_dashboard('step-7')" :class="dashboard_box == 'step-7' ? 'active' : ''" id="pills-address-tab" class="link--icon-left" data-bs-toggle="pill" href="#pills-address" role="tab" aria-controls="pills-address" aria-selected="false"><i class="fas fa-map-marker-alt"></i> Address</a>
                                        </li>
                                        <li>
                                            <a @click="open_dashboard('step-6')" :class="dashboard_box == 'step-6' ? 'active' : ''" id="pills-account-tab" class="link--icon-left" data-bs-toggle="pill" href="#pills-account" role="tab" aria-controls="pills-account" aria-selected="false"><i class="fas fa-user"></i>
                                                Account Details</a>
                                        </li>
                                        <li>
                                            <a @click="open_dashboard('step-5')" :class="dashboard_box == 'step-5' ? 'active' : ''" id="pills-account-tab" class="link--icon-left" data-bs-toggle="pill" href="#pills-account" role="tab" aria-controls="pills-account" aria-selected="false"><i class="fas fa-eye"></i>
                                                Change Password</a>
                                        </li>
                                        <li>
                                            <a class="link--icon-left" @click="logout"><i class="fas fa-sign-out-alt"></i> Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-8 col-md-8">
                                <div class="tab-content my-account-tab" id="pills-tabContent">
                                    <div class="tab-pane fade show active" v-if="dashboard_box == 'step-1'" id="pills-dashboard" role="tabpanel" aria-labelledby="pills-dashboard-tab">
                                        <div class="my-account-dashboard account-wrapper">
                                            <h4 class="account-title">Dashboard</h4>
                                            <div class="welcome-dashboard m-t-30">
                                                <p>Hello, <strong>{{userdetail.username}}</strong> (If Not <strong>{{userdetail.username}} !</strong> <a @click="logout" href="#">Logout</a> )</p>
                                            </div>
                                            <p class="m-t-25">From your account dashboard. you can easily check &amp; view your
                                                recent orders, manage your shipping and billing addresses and edit your password and
                                                account details.</p>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" v-if="dashboard_box == 'step-2'" id="pills-order" role="tabpanel" aria-labelledby="pills-order-tab">
                                        <div class="my-account-order account-wrapper">
                                            <h4 class="account-title">Orders</h4>
                                            <div class="account-table text-center m-t-30 table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>S. No.</th>
                                                            <th>Order Detail</th>
                                                            <th>Shipping Address</th>
                                                            <th>Payment</th>
                                                            <th>Payment Type</th>
                                                            <th>Order Status</th>
                                                            <th>Total</th>
                                                            <th>Invoice</th>
                                                            <th>Actione</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(Order,key) in orderDetailData" :key="key+202">
                                                            <td> {{key+1}} </td>
                                                            <td>
                                                                <a  @click="orderShow(key)" class="check-btn sqr-btn pointer">View</a>
                                                                
                                                                 <div :id="'popup1'+key" class="overlay" :class="showOrder ? 'showOrder' : ''">
                                                                    <div class="popup">
                                                                        <h2>Order Detail</h2>
                                                                        <a class="close pointer theme-color" @click="orderShow(key)">&times;</a>
                                                                        <div class="content py-3">
                                                                            <div class="row align-items-center border">
                                                                                <div class="col-lg-3 border py-2">
                                                                                    Name
                                                                                </div>
                                                                                <div class="col-lg-3 border py-2">
                                                                                    Image
                                                                                </div>
                                                                                <div class="col-lg-3 border py-2">
                                                                                    Quantity
                                                                                </div>
                                                                                <div class="col-lg-3 border py-2">
                                                                                    Price
                                                                                </div>
                                                                            </div>
                                                                            <div class="row align-items-center border border-top-0 " v-for="(myOrder,key) in Order.order" :key="key+112">
                                                                                <div class="col-lg-3 py-2 border-right">
                                                                                    {{myOrder.name}}
                                                                                </div>
                                                                                <div class="col-lg-3 py-2 border-right">
                                                                                    <img :src="$root.URL_ROOT+'uploads/Product/'+myOrder.front_image" width="50">
                                                                                </div>
                                                                                <div class="col-lg-3 py-2 border-right">
                                                                                    {{myOrder.qty}}
                                                                                </div>
                                                                                <div class="col-lg-3 py-2 ">
                                                                                    {{myOrder.price}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                            </td>
                                                            <td>
                                                                <a @click="addressShow(key+100)" class="check-btn sqr-btn pointer">View
                                                                </a>
                                                                <div :id="'popup1'+key+100" class="overlay theme-color" :class="showAddress ? 'showOrder' : ''">
                                                                    <div class="popup">
                                                                        <h2>Order Address</h2>
                                                                        <hr>
                                                                        <a class="close pointer theme-color" @click="addressShow(key+100)">&times;</a>
                                                                        <div class="content">
                                                                            <address>
                                                                                <p><strong> {{Order.addrss.add_title}} </strong></p>
                                                                                <p>{{Order.addrss.add1}} <br>
                                                                                    {{Order.addrss.add2}} <br>
                                                                                    {{Order.addrss.c_state}}, {{Order.addrss.city}} <br>
                                                                                    {{Order.addrss.country}} {{Order.addrss.zipcode}} </p>
                                                                                <p>{{Order.addrss.email}} </p>
                                                                                <p>{{Order.addrss.mobile_no}} </p>
                                                                            </address>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div v-if="Order.status_order==3">
                                                                    <p style="color:green;">Completed</p>
                                                                </div>
                                                                <div v-else>
                                                                    {{Order.payment_status}}
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div v-if="Order.status_order==3">
                                                                    <p style="color:green;">COD Completed</p>
                                                                </div>
                                                                <div v-else>
                                                                    {{Order.paymenttype}}
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div v-if="Order.status_order==5">
                                                                    <p style="color:red;">Order Cancelled</p>
                                                                </div>
                                                                <div v-else-if="Order.status_order==3">
                                                                    <p style="color:green;">Order Completed</p>
                                                                </div>
                                                                <div class="color-green" v-else>
                                                                   Order Placed Successfully
                                                                    <!-- <router-link :to="{ name: 'TrackOrder', params: { id:Order.awb_number } }"> View Order Status </router-link> -->
                                                                </div>
                                                            </td>
                                                            <td> {{Order.total}} </td>
                                                            <td>
                                                                <router-link :to="{ name: 'invoice',params: { id:Order.oid }}"> View Invoice </router-link>
                                                            </td>
                                                            <td>

                                                                <div v-if="Order.status_order==5">
                                                                    <p style="color:red;">
                                                                        Order Cancelled</p>
                                                                </div>
                                                                 <div v-else-if="Order.status_order==3">
                                                                    <p style="color:green;">
                                                                        Completed</p>
                                                                </div>
                                                                <div v-else>
                                                                    <button class="btn btn-danger" @click="cancelOrder(Order.oid,Order.awb_number)">Cancel Order</button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" v-if="dashboard_box == 'step-7'" id="pills-address" role="tabpanel" aria-labelledby="pills-address-tab">
                                        <div class="my-account-address account-wrapper row">
                                            <h4 class="account-title ">My Address</h4>
                                            <div class="account-address m-t-30 col-lg-6 " v-for="address in addressDatas.data" :key="address.add_id+23">
                                                <div class="card pd-20 shadow">
                                                    <h6 class="name">{{address.add_title}}</h6>
                                                    <p>{{address.add1}} <br> {{address.add2}}</p>
                                                    <p> {{address.c_state}}, {{address.city}}</p>
                                                    <p> {{address.country}} {{address.zipcode}}</p>
                                                    <p> {{address.email}} </p>
                                                    <p> {{address.mobile_no}}</p>
                                                    <div class="d-flex justify-content-between">
                                                        <a class="box-btn m-t-25 color-green" @click="editAddress(address.add_id)"><i class="far fa-edit"></i> Edit Address</a>
                                                        <a class="box-btn m-t-25 delete" @click="deleteAdd(address.add_id)"><i class="far fa-trash"></i> Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" v-if="dashboard_box == 'step-6'" id="pills-account" role="tabpanel" aria-labelledby="pills-account-tab">
                                        <div class="my-account-details account-wrapper">
                                            <h4 class="account-title">Account Details</h4>
                                            <div class="account-details">
                                                    <ValidationObserver ref="form1">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname1">Fullname</label>
                                                            <validation-provider rules="required|alpha_spaces|min:3" v-slot="{ errors }">
                                                                <input type="text" v-model="userdetail.username" placeholder="" id="fname1" name="Username">
                                                                <span class="error-msgs">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname2">Mobile</label>
                                                            <validation-provider rules="required|integer|min:10|max:11" v-slot="{ errors }">
                                                                <input type="text" placeholder="" v-model="userdetail.mobile" id="fname2" name="fname">
                                                                <span class="error-msgs">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname2">Email</label>
                                                            <validation-provider rules="required|email" v-slot="{ errors }">
                                                                <input type="text" placeholder="" v-model="userdetail.email" id="fname3" name="fname">
                                                                <span class="error-msgs">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="City">State</label>
                                                            <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                                <input type="text" placeholder="" v-model="userdetail.state" id="state" name="state">
                                                                <span class="error-msgs">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="City">City</label>
                                                            <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                                <input type="text" placeholder="" v-model="userdetail.city" id="City" name="City">
                                                                <span class="error-msgs">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-box__single-group w-50">
                                                            <button type="button" @click="switchLoc2" class="btn btn--box btn--radius btn--small btn--black btn--black-hover-green btn--uppercase font--bold">Save Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                    </ValidationObserver>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" v-if="dashboard_box == 'step-5'" id="pills-password" role="tabpanel" aria-labelledby="pills-account-tab">
                                        <div class="my-account-details account-wrapper">
                                            <h4 class="account-title">Change Password</h4>
                                            <div class="account-details">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-box__single-group">
                                                            <validation-provider rules="required|alpha_num|min:5" v-slot="{ errors }" class="d-flex">
                                                                    <input :type="passshows" v-model="oldPassword" placeholder="Current Password" id="oldPassword" name="Current Password">
                                                                    <span class="input-group-btn">
                                                                        <button class="btn btn-default reveal3" @mousedown="passshows = 'text'" @mouseup="passshows = 'password'" type="button"><i class="fa fa-eye"></i></button>
                                                                    </span>
                                                                    <br />
                                                                    <span class="error-msgs">{{ errors[0] }}</span>
                                                                </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                             <validation-provider rules="required|alpha_num|min:5" v-slot="{ errors }" class="d-flex">
                                                                    <input :type="passshows1" v-model="newPassword" placeholder="New Password" id="newPassword" name="New Password">
                                                                    <span class="input-group-btn">
                                                                        <button class="btn btn-default reveal3" @mousedown="passshows1 = 'text'" @mouseup="passshows1 = 'password'" type="button"><i class="fa fa-eye"></i></button>
                                                                    </span>
                                                                    <br />
                                                                    <span class="error-msgs">{{ errors[0] }}</span>
                                                                </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                             <validation-provider rules="required|alpha_num|min:5" v-slot="{ errors }" class="d-flex">
                                                                    <input :type="passshows2" v-model="confirmPassword" placeholder="Confirm Password" id="confirmPassword" name="Confirm Password">
                                                                    <span class="input-group-btn">
                                                                        <button class="btn btn-default reveal3" @mousedown="passshows2 = 'text'" @mouseup="passshows2 = 'password'" type="button"><i class="fa fa-eye"></i></button>
                                                                    </span>
                                                                    <br />
                                                                    <span class="error-msgs">{{ errors[0] }}</span>
                                                                </validation-provider>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <button type="button" @click="switchLoc" class="btn btn--box btn--radius btn--small btn--black btn--black-hover-green btn--uppercase font--bold">Save Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" id="open2" v-if="dashboard_box == 'step-8'" role="tabpanel">
                                        <div class="my-account-details account-wrapper">
                                            <h4 class="account-title">Edit Address</h4>
                                            <div class="account-details">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname1">Fullname</label>
                                                            <input type="text" v-model="userAddress.add_title" placeholder="Enter address tile..." id="fname1" name="fname">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname2">Mobile</label>
                                                            <input type="text" placeholder="Enter mobile number..." v-model="userAddress.mobile_no" id="fname2" name="Mobile">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="fname2">Email</label>
                                                            <input type="text" placeholder="Enter your email address..." v-model="userAddress.email" id="fname3" name="Email">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="Country">Country</label>
                                                            <select name="Country" v-model="userAddress.country" id="Country" class="form-control">
                                                                <option value="india">India</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="City">State</label>
                                                            <input type="text" placeholder="Enter your state..." v-model="userAddress.c_state" id="fname4" name="State">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="City">City</label>
                                                            <input type="text" placeholder="Enter your city..." v-model="userAddress.city" id="fname5" name="City">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="City">Address Line 1</label>
                                                            <input type="text" placeholder="Enter Address line 1..." v-model="userAddress.add1" id="fname6" name="address line 1">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="Adress">Address Line 2</label>
                                                            <input type="text" placeholder="Enter address line 2..." v-model="userAddress.add2" id="fname7" name="address line 2">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="Post2">Pin Code</label>
                                                            <input type="text" placeholder="Enter your pincode..." v-model="userAddress.zipcode" id="fname8" name="pincode">
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-sm-6 form-box__single-group">
                                                            <button class="btn btn--box btn--radius btn--small btn--black btn--black-hover-green btn--uppercase font--bold" @click="save_address" type="button">Save Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- :::::::::: End My Account Section :::::::::: -->
                </div>
            </div>
        </div>
    </main> <!-- ::::::  End  Main Container Section  ::::::  -->
    <Footer />
</body>
<!-- end of page-wrapper -->
</template>

<script>
import Header from '@/components/navbar'
import Footer from '@/components/footer'
import axios from 'axios'
import $ from "jquery";

import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    name: 'Detail',
    components: {
        Header,
        Footer,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            passshow: 'password',
            passshows: 'password',
            passshows1: 'password',
            passshows2: 'password',
             isCalender: false,
            isHas: false,
            showAddress: false,
            track: false,
            showOrder: false,
            categories: [],
            userAdd: [],
            products: [],
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            uid: localStorage.getItem('user'),
            chk_cat: false,
            chk_pro: false,
            userdetail: [],
            trackOrderDetail: [],
            uname: '',
            stateData: [],
            addressDatas: [],
            addressDetailData: [],
            dashboard_box: 'info',
            orderDetailData: [],
            fname: '',
            lname: '',
            email: '',
            mobile: '',
            newPassword: '',
            confirmPassword: '',
            c_state: '',
            city: '',
            address_name: '',
            address_mobile: '',
            add1: '',
            add2: '',
            zipcode: '',
            mobile_no: '',
            address_state: '',
            address_city: '',
            pincode: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            userAddress: []
        }
    },
    mounted() {
        if (this.uid == '' || this.uid == null) {
            this.$router.push({
                name: 'login'
            });
        }
        this.goToTop()

        this.getUserDetail();
        this.getState();
        this.orderDetail()
        this.goToTop()
        this.open_dashboard('step-1')
        this.getAddress()
    },
    methods: {
        // clickme() {
        //       var x = document.getElementsByTagName("field")
        //             for(var i=0; i<=x.length;i++){
        //                 var check = x[i]['attributes']['name']['nodeValue'];
        //                 console.log(check);
        //                 console.log('Index',i);
        //                 // if(check=='awb_number'){
        //                 // document.getElementById('awb_num').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='orderid'){
        //                 // document.getElementById('oid').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='destination'){
        //                 // document.getElementById('destination').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='current_location_name'){
        //                 // document.getElementById('current_location').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='customer'){
        //                 // document.getElementById('customer').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='status'){
        //                 // document.getElementById('status').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='pickupdate'){
        //                 // document.getElementById('pickupdate').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='tracking_status'){
        //                 // document.getElementById('tracking_status').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='expected_date'){
        //                 // console.log('expected_date',x[i]['innerHTML']);

        //                 // document.getElementById('expected_date').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='delivery_date'){
        //                 // document.getElementById('delivery_date').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='city'){
        //                 // document.getElementById('city').innerHTML = x[i]['innerHTML'];
        //                 // }else if(check=='state'){
        //                 // document.getElementById('state').innerHTML = x[i]['innerHTML'];
        //                 // }
        //         }
        // },
        cancelOrder(oid, awb_number) {
            var ev = this;
            var param = {
                type: "CancelMyOrder",
                awb_number: awb_number,
                uid: ev.uid,
                oid: oid,
            }
            this.$swal({
                title: "Cancel this order ?",
                text: "Are you sure? You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: "#f00",
                confirmButtonText: "Yes, Cancel this order!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'api.php',
                        data: param
                    }).then(function (response) {
                        var data = response.data;
                        ev.orderDetail()
                        console.log(data)
                        // this.deleted = data
                        ev.$toasted.global.success({
                            message: 'Order Cancelled Successfully '
                        })
                    })
                }
            })
        },
        trackMyOrder(awb_number) {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "trackMyOrder",
                    awb_number: awb_number,
                },
            }).then(function (response) {
                var data = response.data;
                ev.trackOrderDetail = data
                // setTimeout(() => {
                //     ev.clickme()
                // }, 5000);

            });
        },
        getAddress() {
            var ip_address = localStorage.getItem("ip_address");
            var user_session = localStorage.getItem("user");
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getAllAddress",
                    uid: uid,
                    ip_address: ip_address,
                },
            }).then(function (response) {
                var data = response.data;
                console.log('getAllAddress', response)
                ev.addressDatas = data;

            });
        },
        addressShow(id) {

            if ($('#popup1' + id).hasClass("showOrder")) {
                $('#popup1' + id).removeClass("showOrder");
            } else {
                $('#popup1' + id).addClass("showOrder");

            }

            if (this.showAddress == false) {
                this.showAddress = true
            } else {
                this.showAddress = false
            }
        },
        orderShow(id) {
            if ($('#popup1' + id).hasClass("showOrder")) {
                $('#popup1' + id).removeClass("showOrder");
            } else {
                $('#popup1' + id).addClass("showOrder");

            }

        },
        trackOrder(awb_number) {
            if (this.track == false) {
                this.trackMyOrder(awb_number)
                this.track = true
            } else {
                this.track = false
            }
        },
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        open_dashboard(vl) {
            this.dashboard_box = vl;
        },
        getUserDetail() {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'userDetail',
                    uid: this.uid
                }
            }).then(function (response) {
                var data = response.data
                if (data.data) {
                    self.userdetail = data.data
                    self.fname = data.fname;
                    self.lname = data.lname;
                    self.email = data.email;
                    self.mobile = data.mobile;
                    self.uname = data.fullname;
                } else {
                    self.logout()
                    self.$toasted.global.error({
                        message: 'Your Account is Deactivated'
                    })
                    setTimeout(function () {
                        self.$router.push({
                            name: 'Home'
                        });
                    }, 1000);
                }

            })

        },
        switchLoc() {
            var vali = this.$refs.form.validate();
            vali.then(val => {
                    if (val == true) {
                        this.changePass()
                    }
                }

            )
        },
        switchLoc2() {
            var vali = this.$refs.form1.validate();
            vali.then(val => {
                    if (val == true) {
                        this.userDetailUpdate()
                    }
                }

            )
        },
        userDetailUpdate() {
            if (this.userdetail.username === '') {
                this.$toasted.global.error({
                    message: 'Please enter First Name'
                })
            } else if (this.userdetail.email === '') {
                this.$toasted.global.error({
                    message: 'Please enter Email'
                })
            } else if (this.userdetail.mobile === '') {
                this.$toasted.global.error({
                    message: 'Please enter Valid Mobile'
                })
            } else if (this.userdetail.address === '') {
                this.$toasted.global.error({
                    message: 'Please enter your address'
                })
            } else if (this.userdetail.state === '') {
                this.$toasted.global.error({
                    message: 'Please enter your state'
                })
            } else if (this.userdetail.city === '') {
                this.$toasted.global.error({
                    message: 'Please enter your City'
                })
            } else {
                var vi = this
                vi.userdetail.type = 'userDetailUpdate'
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: this.userdetail
                }).then(function (response) {
                    var data = response.data
                    if (data.status === 'success') {
                        vi.$toasted.global.success({
                            message: 'Success Please wait...'
                        })
                    } else {
                        vi.$toasted.global.error({
                            message: data.msg
                        })
                    }
                })
            }
        },
        changePass() {
            var ev = this;
            if (this.oldPassword == "") {
                ev.$toasted.global.error({
                    message: 'please enter old password '
                })
            } else if (this.newPassword == "") {
                ev.$toasted.global.error({
                    message: 'please enter new password '
                })

            } else if (this.confirmPassword == "") {
                ev.$toasted.global.error({
                    message: 'please enter re-password '
                })
            } else if (this.newPassword != this.confirmPassword) {
                ev.$toasted.global.error({
                    message: 'password not match'
                })
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'change_password',
                        uid: ev.uid,
                        oldPassword: ev.oldPassword,
                        newPassword: ev.newPassword,
                        confirmPassword: ev.confirmPassword,

                    }
                }).then(function (response) {
                    var data = response.data
                    if (data.status == "success") {
                        ev.oldPassword = "";
                        ev.newPassword = "";
                        ev.confirmPassword = "";
                        $('.error-msg').addClass('hide');
                        setTimeout(function () {
                            $('.error-msgs').removeClass('hide');
                        }, 3000);
                        ev.$toasted.global.success({
                            message: data.msg
                        })
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        })
                    }
                })

            }

        },

        getState() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getState',

                }
            }).then(function (response) {

                var data = response.data
                ev.stateData = data

            })

        },
        orderDetail() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'myorder',
                    uid: ev.uid,

                }
            }).then(function (response) {
                var data = response.data.data
                ev.orderDetailData = data
            })
        },
        saveAdd(id) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'updateAddress',
                    id: id,
                    uid: ev.uid,
                    ip_address: ev.ip_address,
                    fname: ev.fname,
                    lname: ev.lname,
                    add1: ev.add1,
                    add2: ev.add2,
                    city: ev.city,
                    mobile_no: ev.mobile_no,
                    c_state: ev.c_state,
                    zipcode: ev.zipcode
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == "success") {
                    ev.$toasted.global.success({
                        message: data.msg
                    })
                }
                ev.getAddress()

            })
        },
        deleteAdd(id) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'removeAddress',
                    add_id: id,
                }
            }).then(function (response) {
                var data = response.data
                ev.getAddress()
                if (data.status == "success") {
                    ev.$toasted.global.success({
                        message: data.msg
                    })
                }
            })
        },
        editAddress(id) {
            var ev = this;
            this.open_dashboard('step-8')
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'singleAddress',
                    add_id: id,
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == 'success') {
                    ev.userAddress = data.data
                }
            })

        },
        save_address() {
            var ev = this;
            if (this.userAddress.add_title == "") {
                ev.$toasted.global.error({
                    message: 'please enter address title '
                })
            } else if (this.userAddress.mobile_no == "") {
                ev.$toasted.global.error({
                    message: 'please enter mobile number '
                })
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: 'please enter address 1 '
                })
            } else if (this.userAddress.add2 == "") {
                ev.$toasted.global.error({
                    message: 'please enter address 2 '
                })
            } else if (this.userAddress.city == "") {
                ev.$toasted.global.error({
                    message: 'please enter city '
                })
            } else if (this.userAddress.c_state == "") {
                ev.$toasted.global.error({
                    message: 'please select state '
                })
            } else if (this.userAddress.zipcode == "") {
                ev.$toasted.global.error({
                    message: 'please enter pincode '
                })
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'edit_address',
                        uid: ev.uid,
                        add_title: ev.userAddress.add_title,
                        add_id: ev.userAddress.add_id,
                        add1: ev.userAddress.add1,
                        add2: ev.userAddress.add2,
                        mobile: ev.userAddress.mobile_no,
                        email: ev.userAddress.email,
                        country: ev.userAddress.country,
                        pincode: ev.userAddress.zipcode,
                        state: ev.userAddress.c_state,
                        city: ev.userAddress.city
                    }
                }).then(function (response) {
                    var data = response.data
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        })
                        ev.open_dashboard('step-7')
                        ev.getAddress();
                    } else {
                        ev.$toasted.global.error({
                            message: 'Nothing Change'
                        })
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
/*-------- 27. My account style ---------*/
.myaccount-tab-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.myaccount-tab-menu a {
    border: 1px solid #ccc;
    border-bottom: none;
    font-weight: 600;
    font-size: 13px;
    display: block;
    padding: 10px 15px;
    text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
    border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
    background-color: #ff6e21;
    border-color: #ff6e21;
    color: #ffffff !important;
}

.myaccount-tab-menu a i.fa {
    font-size: 14px;
    text-align: center;
    width: 25px;
}

@media only screen and (max-width: 767px) {
    #myaccountContent {
        margin-top: 30px;
    }
}

.myaccount-content {
    border: 1px solid #eeeeee;
    padding: 30px;
}

@media only screen and (max-width: 767px) {
    .myaccount-content {
        padding: 20px 15px;
    }
}

.myaccount-content form {
    margin-top: -20px;
}

.myaccount-content h3 {
    font-size: 20px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    margin-bottom: 25px;
    font-weight: 600;
}

.myaccount-content .welcome a:hover {
    color: #ff6e21;
}

.myaccount-content .welcome strong {
    font-weight: 600;
    color: #ff6e21;
}

.myaccount-content fieldset {
    margin-top: 20px;
}

.myaccount-content fieldset legend {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
    margin-top: 50px;
}

.myaccount-tab-menu.nav a {
    cursor: pointer;
    color: #ff6e21;
}

.myaccount-content .account-details-form .single-input-item {
    margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
    font-size: 14px;
    text-transform: capitalize;
    display: block;
    margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
    border: 1px solid #e8e8e8;
    height: 50px;
    background-color: transparent;
    padding: 2px 20px;
    color: #1f2226;
    font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
    border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
    border: none;
    background-color: #ff6e21;
    text-transform: uppercase;
    font-weight: 600;
    padding: 9px 25px;
    color: #fff;
    font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
    background-color: #1f2226;
}

.myaccount-table {
    /* white-space: nowrap; */
    font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
    padding: 10px;
    font-weight: 600;
    background-color: #f8f8f8;
    border-color: #ccc;
    border-bottom: 0;
    color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
    padding: 10px;
    vertical-align: middle;
    border-color: #ccc;
}

.saved-message {
    background-color: #fff;
    border-top: 3px solid #ff6e21;
    font-size: 14px;
    padding: 20px 0;
    color: #333;
}
.btn-danger {
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}
</style>
