<template>
<!-- ::::::  Start Header Section  ::::::  -->
<header>
    <!--  Start Large Header Section   -->
    <div class="header d-none d-lg-block">
        <!-- Start Header Top area -->
        <div class="header__top">
            <div class="container">
                <div class="row">
                    <div class="col-8">
                        <div class="header__top-content d-flex justify-content-between align-items-center">
                            <div class="header__top-content--left">
                                <span>Free Delivery: Take advantage of our time to save event</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <i class="fal fa-location"></i>
                            Choose Your Location
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- Start Header Top area -->

        <!-- Start Header Center area -->
        <div class="header__center sticky-header p-tb-10">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between align-items-center">
                        <!-- Start Logo -->
                        <div class="header__logo">
                            <router-link to="/" class="header__logo-link img-responsive">
                                <img class="header__logo-img img-fluid" :src="$root.URL_ROOT+'uploads/Content/'+site_logo.image" alt="">
                            </router-link>
                        </div>
                        <!-- End Logo -->
                        <!-- Start Header Menu -->
                        <div class="header-menu">
                            <nav>
                                <ul class="header__nav">
                                    <!--Start Single Nav link-->
                                    <li class="header__nav-item pos-relative">
                                        <router-link to="/" class="header__nav-link">Home</router-link>
                                    </li> <!-- End Single Nav link-->
                                    <!--Start Single Nav link-->
                                    <li class="header__nav-item pos-relative">
                                        <a href="#" class="header__nav-link">Shop <i class="fal fa-chevron-down"></i></a>
                                        <span class="menu-label menu-label--red">New</span>
                                        <!-- Megamenu Menu-->
                                        <ul class="mega-menu pos-absolute">
                                            <li class="mega-menu__box">

                                                <div class="row">
                                                    <template v-for="Category in allCategory" >
                                                        <div class="col-md-3" :key="Category.id">
                                                            <span class="mega-menu__title">
                                                                <router-link class="sub-category-heading" :to="{ name: 'Product', params: {name:Category.heading, id:Category.id} }"> {{Category.heading}} </router-link>
                                                                <div v-if="Category.count>0" class="sub-category-web">
                                                                    <template v-for="sdata in Category.menus">
                                                                        <p :key="sdata.id" class="sub-category-p">
                                                                            <router-link :to="{ name: 'Product', params: {name:sdata.heading, id:sdata.id} }"> {{sdata.heading}} </router-link>
                                                                        </p>
                                                                    </template>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    
                                                </div>



                                                <!--Single Megamenu Item Menu-->
                                                    <!-- <div style="column-count: 3;line-height: 3;">
                                                        <div class="mega-menu__item-box" v-for="Category in allCategory" :key="Category.id">
                                                            <span class="mega-menu__title">
                                                                <router-link :to="{ name: 'Product', params: {name:Category.heading, id:Category.id} }"> {{Category.heading}} </router-link>
                                                                <div v-if="Category.count>0" class="sub-category-web">
                                                                    <template v-for="sdata in Category.menus">
                                                                        <p :key="sdata.id">
                                                                            <router-link :to="{ name: 'Product', params: {name:sdata.heading, id:sdata.id} }"> {{sdata.heading}} </router-link>
                                                                        </p>
                                                                    </template>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div> -->
                                                    
                                                    <!-- <div>
                                                        <div class="mega-menu__item-box">
                                                            <ul class="mega-menu__item">
                                                               
                                                                <li class="mega-menu__banner ">
                                                                    <a href="#" class="mega-menu__banner-link">
                                                                        <img src="../assets/img/banner/menu-banner.jpg" alt="" class="mega-menu__banner-img mega-menu__banner-img--vertical">
                                                                    </a>
                                                                </li>
                                                                
                                                            </ul>
                                                        </div>
                                                    </div> -->
                                                    
                                                <!--Single Megamenu Item Menu-->
                                            </li>

                                        </ul>
                                        <!-- Megamenu Menu-->
                                    </li> <!-- Start Single Nav link-->

                                    <li class="header__nav-item pos-relative">
                                        <router-link :to="{ name: 'aboutus' }" class="header__nav-link">About Us</router-link>
                                    </li><!-- End Single Nav link-->
                                    <!--Start Single Nav link-->
                                    <li class="header__nav-item pos-relative">
                                        <router-link :to="{name:'faq'}" class="header__nav-link">Frequently Questions</router-link>
                                    </li> <!-- End Single Nav link-->
                                    <li class="header__nav-item pos-relative">
                                        <router-link :to="{ name: 'contactus' }" class="header__nav-link">Contact Us</router-link>
                                    </li> <!-- End Single Nav link-->
                                </ul>
                            </nav>
                        </div> <!-- End Header Menu -->
                        <!-- Start Wishlist-AddCart -->
                        <ul class="header__user-action-icon ">

                             <!-- Start Header Search Box -->
                            <!-- Start Header Wishlist Box -->
                            <li>
                                <a class="pointer" @click="isSearchMenu ^= true">
                                    <i class="icon-search"></i>
                                </a>
                                <ul class="menu-content pos-absolute z-index-9 user-menu shadow search-dropdown" v-show="isSearchMenu" style="">
                                    <li>
                                    <div class="header-search__content pos-relative">
                        <input type="search" v-on:keyup.enter="search" v-model="SearchKey" name="header-search" placeholder="Search our store" required="">
                        <button class="pos-absolute" type="button" @click="search"><i class="icon-search"></i></button>
                    </div>
                                    </li>
                                </ul>
                            </li> <!-- End Header Wishlist Box -->
                           <!-- End Header Search Box -->

                            <!-- Start Header Wishlist Box -->
                            <li>
                                <a class="pointer" @click="isUserMenu ^= true">
                                    <i class="icon-users"></i>
                                </a>
                                <ul class="menu-content pos-absolute z-index-9 user-menu shadow" v-show="isUserMenu" style="">
                                    <li>
                                        <router-link v-if="uid" :to="{name: 'myaccount'}">My Account</router-link>
                                    </li>
                                    <li>
                                        <router-link v-if="!uid" :to="{name: 'login'}">Login</router-link>
                                    </li>
                                    <li>
                                        <router-link v-if="!uid" :to="{name: 'registration'}">Register</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name: 'cart'}">Cart</router-link>
                                    </li>
                                    <li class="hide">
                                        <router-link :to="{name: 'wishlist'}">Wishlist</router-link>
                                    </li>
                                    <li v-if="uid">
                                        <router-link :to="{name: 'checkout'}">Checkout</router-link>
                                    </li>
                                    <li>
                                        <a href="#" v-if="uid" @click="logout">Logout</a>
                                    </li>
                                </ul>
                            </li> <!-- End Header Wishlist Box -->
                            <!-- Start Header Wishlist Box -->
                            <li>
                                <router-link :to="{ name: 'wishlist' }">
                                    <i class="icon-heart"></i>
                                    <!-- <span class="item-count pos-absolute">3</span> -->
                                </router-link>
                            </li> <!-- End Header Wishlist Box -->
                            <!-- Start Header Add Cart Box -->
                            <li>
                                <router-link :to="{ name: 'cart' }" class="offcanvas-toggle">
                                    <i class="icon-shopping-cart"></i>
                                    <span class="wishlist-item-count pos-absolute" id="cartcount"> {{cartCount.count}} </span>
                                </router-link>
                            </li> <!-- End Header Add Cart Box -->
                        </ul>
                    </div>
                </div>
            </div>
        </div> <!-- End Header Center area -->
    </div> <!--  End Large Header Section  -->
    <!--  Start Mobile Header Section   -->
    <div class="header__mobile mobile-header--1 d-block d-lg-none p-tb-20">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 d-flex align-items-center justify-content-between">
                    <ul class="header__mobile--leftside d-flex align-items-center justify-content-start">
                        <li>
                            <div class="header__mobile-logo">
                                <router-link to="/" class="header__mobile-logo-link">
                                    <img :src="$root.URL_ROOT+'uploads/Content/'+site_logo.image" alt="" class="header__mobile-logo-img">
                                </router-link>
                            </div>
                        </li>
                    </ul>
                    <!-- Start User Action -->
                    <ul class="header__mobile--rightside header__user-action-icon  d-flex align-items-center justify-content-end">
                        <!-- Start Header Add Cart Box -->
                        <li>
                           <router-link :to="{ name: 'cart' }">
                                <i class="icon-shopping-cart" ></i>
                                <span class="wishlist-item-count pos-absolute" id="mobileCartCount1"></span>
                            </router-link>
                        </li> <!-- End Header Add Cart Box -->
                        <li><a @click="isMobileMenu ^= true" class="offcanvas-toggle"><i class="far fa-bars"></i></a></li>
                    </ul> <!-- End User Action -->
                </div>
            </div>
        </div>
        <transition name="fade">
            <div id="offcanvas-mobile-menu" v-show="isMobileMenu" class="offcanvas offcanvas-mobile-menu offcanvas-open shadow">
                <div class="offcanvas__top">
                    <span class="offcanvas__top-text"></span>
                    <button @click="isMobileMenu ^= true" class="offcanvas-close"><i class="fal fa-times"></i></button>
                </div>
                <div class="offcanvas-inner">
                    <!-- <form class="header-search m-tb-15" action="#" method="post"> -->
                    <div class="header-search__content pos-relative">
                        <input type="search" v-on:keyup.enter="search" v-model="SearchKey" name="header-search" placeholder="Search our store" required="">
                        <button class="pos-absolute" type="button" @click="search"><i class="icon-search"></i></button>
                    </div>
                    <!-- </form> -->
                    <!-- Start Mobile User Action -->
                    <ul class="header__user-action-icon m-tb-15 text-center">
                        <!-- Start Header Wishlist Box -->
                        <li v-if="uid">
                            <router-link :to="{ name: 'myaccount' }">
                                <i class="icon-users"></i>
                            </router-link>
                        </li> 
                        <!-- End Header Wishlist Box -->
                         <!-- Start Header Wishlist Box -->
                        <li v-if="!uid">
                            <router-link :to="{ name: 'login' }">
                                <i class="icon-users"></i>
                            </router-link>
                        </li> 
                        <!-- End Header Wishlist Box -->
                        <!-- Start Header Wishlist Box -->
                        <li>
                             <router-link :to="{ name: 'wishlist' }">
                                <i class="icon-heart"></i>
                                <!-- <span class="item-count pos-absolute">3</span> -->
                            </router-link>
                        </li> <!-- End Header Wishlist Box -->
                        <!-- Start Header Add Cart Box -->
                        <li>
                             <router-link :to="{ name: 'cart' }">
                                <i class="icon-shopping-cart" ></i>
                                <span class="wishlist-item-count pos-absolute" id="mobileCartCount"></span>
                            </router-link>
                        </li> <!-- End Header Add Cart Box -->
                    </ul> <!-- End Mobile User Action -->
                    <div class="offcanvas-menu">
                        <ul>
                            <li>
                                <router-link to="/"><span>Home</span></router-link>
                            </li>
                            <li v-for="Category in allCategory" :key="Category.id">
                                <router-link :to="{ name: 'Product', params: {name:Category.heading, id:Category.id} }"> {{Category.heading}} </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="offcanvas__social-nav m-t-50">
                    <li class="offcanvas__social-list" v-if="LinksDetails.facebook_link"><a :href="LinksDetails.facebook_link" class="offcanvas__social-link"><i class="fab fa-facebook-f"></i></a></li>
                    <li class="offcanvas__social-list"  v-if="LinksDetails.twitter_link"><a :href="LinksDetails.twitter_link" class="offcanvas__social-link"><i class="fab fa-twitter"></i></a></li>
                    <li class="offcanvas__social-list" v-if="LinksDetails.google_link"><a :href="LinksDetails.google_link" class="offcanvas__social-link"><i class="fab fa-google-plus-g"></i></a></li>
                    <li class="offcanvas__social-list" v-if="LinksDetails.instagram_link"><a :href="LinksDetails.instagram_link" class="offcanvas__social-link"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </transition>
            <div class="offcanvas-overlay" v-show="isMobileMenu" style="display: block;"></div>
    </div> <!--  Start Mobile Header Section   -->
</header> <!-- :::::: End Header Section ::::::  -->
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: ["isHas"],
    data() {
        return {
            activeClass: false,
            isMobileMenu: false,
            isSearchMenu: false,
            SearchKey: '',
            LinksDetails: [],
            isSubCategory: false,
            isUserMenu: false,
            showMe: false,
            isToggle: false,
            myaccountdropdown: false,
            wish: [],
            isShowing: false,
            uid: localStorage.getItem("user"),
            cartCount: [],
            postroute: [],
            searchData: [],
            allCategory: [],
            catedatas: [],
            site_logo: [],
            fields: {
                type: "nav",
                uid: localStorage.getItem("user"),
                fullname: "",
                mobile: "",
                address: "",
                Token: this.$root.auth,
            },
        };
    },
    mounted() {
        if (this.$route.name == 'Product') {
            this.activeClass = true
        }
        this.isShowing = this.isHas
        this.shownav();
        this.getCart();
        this.categories();
        this.site_logos();
        this.site_setting()
    },
    watch: {
        cartCount: function (cartCount) {
            // if(this.cartCount.count != cartCount.count){
            this.cartCount = cartCount;
            // this.getCart();
            // }
        },
    },
    methods: {
        search() {
            if (this.SearchKey == '') {
                this.$toasted.global.error({
                    message: 'Please enter product or category name'
                })
            } else {
                this.$emit("change", this.SearchKey);
                this.$router.push({
                    name: 'Product',
                    query: {
                        searchkey: this.SearchKey
                    }
                }).catch(() => {});
            }
        },
        showNav() {
            if (this.showMe == false) {
                this.showMe = true;
            } else {
                this.showMe = false;
            }
        },
        showToggle() {
            if (this.isToggle == true) {
                this.isToggle = false
            } else {
                this.isToggle = true
            }
        },
        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
        },
        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
        },
        shownav() {
            this.responsive = true;
        },
        categories() {
            var ev = this;
            axios
                .get(this.$root.URL_ROOT + "api.php?type=getMyCategory")
                .then(function (response) {
                    var data = response.data
                    if (data.status == "success") {
                        ev.allCategory = data.category;
                        console.log('category',data)
                    }
                });
        },
        whichCount() {
            var ev = this;
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            axios
                .post(this.$root.URL_ROOT + "api.php?type=whichCount", {
                    uid: uid,
                    ip_address: ip_address,
                })
                .then(function (response) {
                    ev.wish = response.data;
                    // console.log(response.data)
                });
        },
    },
};
</script>

<style>
.showclass {
    opacity: 1 !important;
    left: 0 !important;
    top: 0px;
    z-index: 99999999999 !important;
    visibility: visible !important;
    padding-top: 50px;
}

.shadow {
    box-shadow: 1px 4px 9px #5d5b5b;
}

.user-menu li:hover{
    background: #9b272c !important;
}
</style>
