<template>
<carousel :autoplay="false" v-if="showData" :nav="false" :items="1" :responsive="{ 0: { items: 1 }, 576: { items: 1 }, 600: { items: 1 }, 768: { items: 1 }, 1200: { items: 1 }, 1500: { items: 1 },}">
    <div class="testimonial__content--slider" v-for="data in pageData" :key="data.id">
        <div class="testimonial__single">
            <p class="testimonial__desc" v-html="data.description"></p>
            <div class="testimonial__info">
                <img class="testimonial__info--user" :src="$root.URL_ROOT+'uploads/Content/'+data.image" style="width:auto;" alt="">
                <h5 class="testimonial__info--desig m-t-20">{{data.heading}}</h5>
            </div>
        </div>
    </div>
</carousel>
</template>

<script>
import carousel from 'vue-owl-carousel2'
export default {
    components: {
        carousel,
    },
    data() {
        return {
            pageData: [],
            showData: false
        }
    },
    mounted() {
        this.pData()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        pData() {
            const axios = require('axios')
            var self = this
            axios.post(this.$root.URL_ROOT + 'api.php', {
                type: 'testimonial'
            }).then(function (response) {
                var data = response.data
                self.pageData = data.data
                self.showData = true

            })
        }
    }
}
</script>
